import { isObject } from '@/src/lib/utils';
import { Webpage } from '@/src/modules/resources/resources.types';
import {
  CreateBaseResourceParams,
  CreateFolderParams,
  CreateImageWebnoteParams,
  CreatePageWebnoteParams,
  CreateResourceTypes,
  CreateTextWebnoteParams,
  EditorJSData,
  FabricResourceTypes,
  FabricSearchHit,
  ImageContext,
  PageContext,
  PrivateTag,
  Resource,
  TextContext,
} from '@fabric/woody-client';
import { UserPublicInfo } from '@fabric/woody-client/src/types/user';
import { omit } from '../lib/store';
import { Comment } from '../modules/comments/comments.types';
import { fabricHitCommentNestedToComment } from '../modules/comments/utils/fabricHitCommentNestedToComment';
import { Space } from '../modules/spaces/spaces.types';
import { rootTypeGuard } from '../modules/spaces/utils/rootTypeGuard';

export enum ResourceState {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const parseResourceState = (state: string): ResourceState => {
  return Object.values(ResourceState).includes(state as ResourceState)
    ? (state as ResourceState)
    : ResourceState.FAILED;
};

type StoredFileFDocData = {
  title: string;
  extension: string | null;
  contentType: string | null;
  contentLength: number | null;
  url: string;
  thumbnail?: {
    sm: string;
    md: string;
    lg: string;
  };
};

type HalNotepad = {
  type: 'notepad';
  title: string;
  content?: string | null;
  editorjs?: EditorJSData | null;
  isYjsEnabled: boolean;
  modifiedAt: Date;
  createdAt: Date;
};

export const hasCreationAndModifiedDates = (
  obj: unknown,
): obj is { modifiedAt: Date; createdAt: Date } => {
  return Boolean(
    obj &&
      isObject(obj) &&
      (obj.modifiedAt instanceof Date || typeof obj.modifiedAt === 'string') &&
      (obj.createdAt instanceof Date || typeof obj.createdAt === 'string'),
  );
};

type Webnote = {
  pageUrl: string;
  pageTitle: string;
  statePreview: ResourceState;
  webpage?: Webpage | null;
};

export type Thumbnail = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export type User = Omit<UserPublicInfo, 'modifiedAt' | 'createdAt' | '_links'>;

export type Ancestor = {
  id: string;
  name: string;
};

// Experimental - idea is to potentially show status of things on the expanded view
export interface FdocMeta {
  isDeleting?: boolean;
  isUpdating?: boolean;
  isMoving?: boolean;

  placeholderImage?: string;
}

export interface BaseFdoc {
  id: string;

  parentResourceId: string | null;
  isDirectShared: boolean;
  isShared: boolean;
  stateProcessing: ResourceState;
  commentCount: number | null;
  commentPinned: Comment | null;
  commenters: FabricSearchHit['commenters'];
  label: number | null;
  list: string | null;
  groupKey?: string;
  vectorDistance?: number;
  listData?: Space;

  personal: {
    tags: PrivateTag[];
  };

  originUrl: string | null;

  createdAt: string;
  modifiedAt: string;

  user: User | null;

  _meta?: FdocMeta;
  thumbnail?: Thumbnail | null;
  fileUrl?: string | null;
}

export interface PageFdoc extends BaseFdoc {
  type: 'page';
  data: Webnote &
    PageContext & {
      screenshotUrl?: string;
      textUrl?: string;
    };
  isWebnote: true;
}

export interface ImageFdoc extends BaseFdoc {
  type: 'image';
  data: Webnote & ImageContext;
  isWebnote: true;
  thumbnail?: Thumbnail | null;
}

export interface TextFdoc extends BaseFdoc {
  type: 'text';
  data: Webnote & TextContext;
  isWebnote: true;
}

export interface NotepadFdoc extends BaseFdoc {
  type: 'notepad';
  data: Pick<
    HalNotepad,
    'content' | 'title' | 'editorjs' | 'isYjsEnabled' | 'modifiedAt' | 'createdAt'
  >;
  isWebnote: false;
}

export interface StoredFileFdoc extends BaseFdoc {
  type: 'stored_file';
  data: Omit<StoredFileFDocData, 'thumbnail'> & {
    thumbnail: Thumbnail | null;
  };
  isWebnote: false;
  key?: string;
}

export interface FolderFdoc extends BaseFdoc {
  type: 'folder';
  data: {
    name: string;
  };
  isWebnote: false;
}

export type Fdoc = PageFdoc | ImageFdoc | TextFdoc | NotepadFdoc | StoredFileFdoc | FolderFdoc;

export const getThumbnail = (
  thumbnail?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  } | null,
) => {
  return thumbnail && thumbnail.sm && thumbnail.md && thumbnail.lg && thumbnail.xl
    ? {
        sm: thumbnail.sm,
        md: thumbnail.md,
        lg: thumbnail.lg,
        xl: thumbnail.xl,
      }
    : null;
};

export const convertWoodyResourceToFdoc = (resource: Resource): Fdoc => {
  const baseFdoc: BaseFdoc = {
    id: resource.id,
    commentCount: resource.commentCount ?? null,
    label: resource.label?.id ?? null,
    list: resource.list?.id ?? null,
    listData: {
      id: resource.list?.id ?? '',
      title: resource.list?.title ?? '',
      type: rootTypeGuard(resource.list?.type) ? resource.list.type : 'SPACE',
      integration: resource.list?.integration ?? null,
      externalId: resource.list?.externalId ?? null,
    },
    createdAt: new Date(resource.createdAt).toISOString(),
    modifiedAt: new Date(resource.modifiedAt).toISOString(),
    parentResourceId: resource.parentResourceId,
    isDirectShared: resource.isDirectShared,
    isShared: resource.isShared,
    stateProcessing: parseResourceState(resource.stateProcessing),
    commenters: [],
    commentPinned: fabricHitCommentNestedToComment(resource.commentPinned, resource.id),
    originUrl: resource.originUrl,
    personal: {
      tags: resource.personal.tags,
    },

    user: resource.user,
  };

  switch (resource.type) {
    case 'page':
      return {
        ...baseFdoc,
        type: 'page',
        data: {
          ...resource.item.context,
          pageUrl: resource.item.pageUrl,
          pageTitle: resource.item.pageTitle,
          statePreview: parseResourceState(resource.item.statePreview),
          screenshotUrl: resource.item.screenshotUrl,
          textUrl: resource.item.textUrl,
        },
        isWebnote: true,
      };
    case 'image':
      return {
        ...baseFdoc,
        type: 'image',
        data: {
          ...resource.item.context,
          pageUrl: resource.item.pageUrl,
          pageTitle: resource.item.pageTitle,
          statePreview: parseResourceState(resource.item.statePreview),
        },
        isWebnote: true,
        thumbnail: getThumbnail(resource.thumbnail),
      };
    case 'text':
      return {
        ...baseFdoc,
        type: 'text',
        data: {
          ...resource.item.context,
          pageUrl: resource.item.pageUrl,
          pageTitle: resource.item.pageTitle,
          statePreview: parseResourceState(resource.item.statePreview),
        },
        isWebnote: true,
      };
    case 'notepad':
      const { modifiedAt, createdAt } = hasCreationAndModifiedDates(resource.item)
        ? resource.item
        : resource;

      return {
        ...baseFdoc,
        type: 'notepad',
        data: {
          title: resource.item.title,
          content: resource.item.content ?? null, // Need to make sure it's not undefined as it will bug shared direct fdocs
          editorjs: resource.item.editorjs,
          isYjsEnabled: resource.item.isYjsEnabled,
          modifiedAt,
          createdAt,
        },
        isWebnote: false,
      };
    case 'stored_file':
      return {
        ...baseFdoc,
        type: 'stored_file',
        data: {
          title: resource.item.title,
          extension: resource.item.extension,
          contentType: resource.item.contentType,
          contentLength: resource.item.contentLength,
          url: resource.item.url,
          thumbnail: getThumbnail(resource.thumbnail) || getThumbnail(resource.item.thumbnail),
        },
        isWebnote: false,
      };
    case 'folder':
      return {
        ...baseFdoc,
        type: 'folder',
        data: {
          name: resource.item.name,
        },
        isWebnote: false,
      };
    default:
      throw new Error(`Invalid resource type: ${resource}`);
  }
};

export const convertFabricSearchHitToFdoc = (woodyHit: FabricSearchHit): Fdoc => {
  const baseFdoc: BaseFdoc = {
    ...woodyHit,
    id: woodyHit.id,
    commentCount: woodyHit.commentCount,
    label: woodyHit.label,
    list: woodyHit.list?.id ?? null,
    createdAt: woodyHit.createdAt,
    modifiedAt: woodyHit.modifiedAt,
    isShared: woodyHit.isShared,
    isDirectShared: woodyHit.isDirectShared,
    stateProcessing: parseResourceState(woodyHit.stateProcessing),
    listData: {
      id: woodyHit.list?.id ?? '',
      title: woodyHit.list?.title ?? '',
      type: rootTypeGuard(woodyHit.list?.type) ? woodyHit.list.type : 'SPACE',
      integration: woodyHit.list?.integration ?? null,
    },
    originUrl: woodyHit.originUrl ?? null,
    personal: {
      tags: [],
    },
    commentPinned: fabricHitCommentNestedToComment(woodyHit.commentPinned, woodyHit.id),
    user: woodyHit.user,
  };

  switch (woodyHit.type) {
    case FabricResourceTypes.PAGE:
      return {
        ...baseFdoc,
        type: 'page',
        data: {
          noteType: woodyHit.data.context.noteType,
          pageUrl: woodyHit.data.pageUrl,
          pageTitle: woodyHit.data.pageTitle,
          statePreview: parseResourceState(woodyHit.stateProcessing),
        },
        isWebnote: true,
      };
    case FabricResourceTypes.IMAGE:
      return {
        ...baseFdoc,
        type: 'image',
        data: {
          noteType: woodyHit.data.context.noteType,
          pageUrl: woodyHit.data.pageUrl,
          pageTitle: woodyHit.data.pageTitle,
          domImageLSH: woodyHit.data.context.domImageLSH,
          domImageUrl: woodyHit.data.context.domImageUrl,
          statePreview: parseResourceState(woodyHit.stateProcessing),
          closestPath: '',
        },
        isWebnote: true,
      };
    case FabricResourceTypes.TEXT:
      return {
        ...baseFdoc,
        type: 'text',
        data: {
          noteType: woodyHit.data.context.noteType,
          pageUrl: woodyHit.data.pageUrl,
          pageTitle: woodyHit.data.pageTitle,
          text: woodyHit.data.context.text,
          textRange: woodyHit.data.context.textRange,
          statePreview: parseResourceState(woodyHit.stateProcessing),
        },
        isWebnote: true,
      };
    case FabricResourceTypes.NOTEPAD:
      const { modifiedAt, createdAt } = hasCreationAndModifiedDates(woodyHit.data)
        ? woodyHit.data
        : woodyHit;

      return {
        ...baseFdoc,
        type: 'notepad',
        data: {
          title: woodyHit.data.title,
          editorjs: woodyHit.data.editorjs,
          isYjsEnabled: woodyHit.data.isYjsEnabled ?? true,
          modifiedAt: new Date(modifiedAt),
          createdAt: new Date(createdAt),
        },
        isWebnote: false,
      };
    case FabricResourceTypes.STORED_FILE:
      return {
        ...baseFdoc,
        type: 'stored_file',
        data: {
          url: '',
          contentLength: woodyHit.data.contentLength,
          contentType: woodyHit.data.contentType,
          extension: woodyHit.data.extension,
          title: woodyHit.data.title,
          thumbnail: null,
        },
        isWebnote: false,
      };
    case FabricResourceTypes.FOLDER:
      return {
        ...baseFdoc,
        type: 'folder',
        data: {
          name: woodyHit.data.name,
        },
        isWebnote: false,
      };
    default:
      throw new Error(`Invalid resource type: ${woodyHit.type}`);
  }
};

export const getFdocURL = (fdoc: Fdoc): string | null => {
  if (fdoc.type === 'stored_file' && fdoc.originUrl) return fdoc.originUrl;
  if (!fdoc.isWebnote) return null;

  return fdoc.data.pageUrl + (fdoc.type !== 'page' ? `#${fdoc.id}` : '');
};

export enum CategoryToFamily {
  All = 'all', // not used, just to keep typescript happy
  Highlights = 'highlight',
  Images = 'image',
  Bookmarks = 'page',
  Files = 'generic_file',
  Notes = 'notepad',
}

/**
 * @deprecated
 */
export type FdocOfType<T extends Fdoc['type']> = Fdoc & { type: T };

interface CreateFileParams extends CreateBaseResourceParams {
  type: FabricResourceTypes.STORED_FILE;
  key: string;
  title: string;

  optimistic: {
    contentLength: number;
    contentType: string;
    extension: string;
    thumbnail: Thumbnail | null;
    url: string;
    isBlobUrl?: boolean;
  };
}

interface CreateNotepadParams extends CreateBaseResourceParams {
  type: FabricResourceTypes.NOTEPAD;
  title: string;
  content?: string | null;
  editorjs?: EditorJSData | null;
  state?: Uint8Array | null;
}

export type CreateResourceData =
  | CreateFolderParams
  | CreatePageWebnoteParams
  | CreateImageWebnoteParams
  | CreateTextWebnoteParams
  | CreateNotepadParams
  | CreateFileParams;

export const cleanupResourceData = (data: CreateResourceData): CreateResourceTypes => {
  switch (data.type) {
    case FabricResourceTypes.STORED_FILE:
      return omit(data, ['optimistic']);
    case FabricResourceTypes.NOTEPAD:
      return omit(data, ['state']);
    default:
      return data;
  }
};
