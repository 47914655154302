import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useSocketIOContext } from '@/src/lib/socket.io/useSocketIOContext';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { convertResourceV2ToFdoc } from '@/src/modules/resources/utils/convertResourceV2ToFdoc';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import {
  QueryObserverOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React from 'react';
import { resourceQueryKeys } from './resourceQueryKeys';

const getRefetchInterval = (resource: ResourceDetail | undefined) => {
  if (!resource) {
    return 45000;
  }

  if (
    // If it's a notepad it has more potential to change
    // note: note sure if it's relevant with yjs?
    resource.kind === 'notepad'
  )
    return 15000;

  if (
    isResourceStateProcessing(resource.stateProcessing) ||
    ('data' in resource &&
      resource.data &&
      'statePreview' in resource.data &&
      // incorrect typing
      isResourceStateProcessing(resource.data.statePreview as any))
  ) {
    return 5000;
  }

  return 45000;
};

/**
 * either resource id or object with resourceId and accessToken
 */
type ResourceIdParam = string | null | { resourceId: string; accessToken: string };

const getIdAndTokenFromParams = (params?: ResourceIdParam) => {
  if (!params) {
    return {
      resourceId: null,
      accessToken: undefined,
    };
  }
  if (typeof params === 'string') {
    return {
      resourceId: params,
      accessToken: undefined,
    };
  }

  return params;
};

/**
 * Fetches resource details using v2.
 *
 * @param resourceId
 * @param queryOptions
 * @returns
 */
export const useQueryResourceDetail = (
  params?: ResourceIdParam,
  queryOptions?: Partial<QueryObserverOptions<ResourceDetail>>,
) => {
  const { client } = useWoody();
  const queryClient = useQueryClient();

  const { resourceId, accessToken } = getIdAndTokenFromParams(params);

  const query = useQuery({
    queryKey: resourceQueryKeys.resourceDetail(resourceId),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;

      const res = await client.v2(
        {
          endpoint: '/v2/resources/{resourceId}',
          params: {
            resourceId: safeResourceId,
          },
        },
        {
          query: {
            accessToken,
          },
        },
      );

      /**
       * swagger generated types are not deterministic enough
       */
      return res as ResourceDetail;
    },
    retry: (failureCount, error) => {
      if ((isWoodyError(error) && [404, 401, 403].includes(error.status)) || failureCount >= 3) {
        return false;
      }
      return true;
    },
    placeholderData: keepPreviousData,
    refetchOnMount: true,
    refetchInterval: (query) => getRefetchInterval(query.state.data),
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, queryOptions?.enabled]),
  });

  /**
   * Establish socket listener for attribute updates
   * This can happen when the data are not available in the initial response
   * as there's on demand processing of the data in the backend
   */
  const { socket } = useSocketIOContext();

  React.useEffect(() => {
    if (resourceId) {
      const updater = (socketData: { resourceId: string; attributes: Record<string, unknown> }) => {
        if (socketData.resourceId === resourceId) {
          const queryKey = resourceQueryKeys.resourceDetail(resourceId);
          queryClient.setQueryData(queryKey, (prevData: ResourceDetail | undefined) => {
            if (!prevData) return prevData;

            return {
              ...prevData,
              data:
                'data' in prevData
                  ? {
                      ...prevData.data,
                      attributes: socketData.attributes,
                    }
                  : {
                      attributes: socketData.attributes,
                    },
            };
          });
        }
      };

      socket.on('RESOURCE:METADATA_ATTRIBUTES:UPDATE', updater);
      return () => {
        socket.off('RESOURCE:METADATA_ATTRIBUTES:UPDATE', updater);
      };
    }
  }, [socket, resourceId, queryClient]);

  /**
   * @deprecated
   */
  const fdoc = React.useMemo(() => {
    if (!query.data) {
      return undefined;
    }
    return convertResourceV2ToFdoc(query.data);
  }, [query.data]);

  return {
    ...query,
    resource: query.data,
    /**
     * @deprecated
     */
    fdoc,
  };
};
