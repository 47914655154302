import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { QueryObserverOptions } from '@tanstack/react-query';
import React from 'react';

/**
 * fetches a given folder by id
 *
 * @param queryOptions
 * @returns
 */
export const useQueryFolder = (
  resourceId?: string | null,
  queryOptions?: Partial<QueryObserverOptions<ResourceDetail>>,
) => {
  const queryResourceDetail = useQueryResourceDetail(resourceId, queryOptions);

  const folder = React.useMemo(() => {
    return queryResourceDetail.fdoc?.type === 'folder' ? queryResourceDetail.fdoc : null;
  }, [queryResourceDetail.fdoc]);

  const folderV2 = React.useMemo(() => {
    if (queryResourceDetail.resource?.kind === 'folder') {
      return queryResourceDetail.resource;
    }
  }, [queryResourceDetail.resource]);

  return {
    ...queryResourceDetail,
    /**
     * @deprecated use data instead for v2
     */
    folder: folder,
    folderV2,
  };
};
